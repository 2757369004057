import React, { useEffect, useState } from "react";
import "./AgentCalendar.css";
import DetailsPopup from "./DetailsPopup/DetailsPopup";

const AgentCalendar = ({ currentAgent }) => {
  const [rdvs, setRdvs] = useState([[], [], [], [], []]);
  const [dragging, setDragging] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(0);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [adresse, setAdresse] = useState(null);
  const [clientName, setClientName] = useState(null);

  const [date, setDate] = useState(null);
  const [dayIndex, setDayIndex] = useState(null);
  const [startInterval, setStartInterval] = useState(null);
  const [endInterval, setEndInterval] = useState(null);

  const [startIndex, setStartIndex] = useState(null);
  const [endIndex, setEndIndex] = useState(null);
  const [draggingDayIndex, setDraggingDayIndex] = useState(null);
  useEffect(() => {
    fetchRdvs();
  }, [currentAgent, currentWeek]);

  function getOnlyThisWeek(arrays) {
    const today = new Date();
    const startOfWeek = new Date(
      today.setDate(
        today.getDate() -
          (today.getDay() === 0 ? 6 : today.getDay() - 1) +
          7 * currentWeek
      )
    );
    startOfWeek.setHours(0, 0, 0, 0);
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 4));
    endOfWeek.setHours(23, 59, 59, 999);

    const weekArray = [[], [], [], [], []];

    arrays.forEach((array) => {
      const date = new Date(array[0].date);
      date.setHours(0, 0, 0, 0);
      const dayIndex = date.getDay();

      if (
        dayIndex >= 1 &&
        dayIndex <= 5 &&
        date >= startOfWeek &&
        date <= endOfWeek
      ) {
        weekArray[dayIndex - 1] = array;
      }
    });
    return weekArray;
  }

  const fetchRdvs = async () => {
    try {
      const response = await fetch(`/get-rdv-by-agent/${currentAgent}`, {});
      const data = await response.json();
      setRdvs(getOnlyThisWeek(data));
    } catch (error) {
      console.error("Failed to fetch RDVs:", error);
    }
  };

  const handleMouseDown = (time, index, dayIndex) => {
    setDragging(true);
    setStartTime(time);
    setStartIndex(index);
    setDraggingDayIndex(dayIndex);
  };

  const handleMouseUp = (date, dayIndex) => {
    setDragging(false);
    if (startTime !== null && endTime !== null) {
      const startInterval = Math.floor(
        (startTime.getHours() * 60 + startTime.getMinutes() - 480) / 15
      );
      const endInterval = Math.floor(
        (endTime.getHours() * 60 + endTime.getMinutes() - 480) / 15
      );
      setDate(date);
      setDayIndex(dayIndex);
      setStartInterval(startInterval);
      setEndInterval(endInterval);
      setDetailsPopup(true);
    }
    setStartTime(null);
    setEndTime(null);
    setStartIndex(null);
    setEndIndex(null);
    setDraggingDayIndex(null);
  };

  const handleMouseEnter = (time, index) => {
    if (dragging) {
      setEndTime(time);
      setEndIndex(index);
    }
  };

  const createRdv = async (date, dayIndex, start, end) => {
    if (start > end) {
      let temp = start;
      start = end;
      end = temp;
    }

    const isOverlapping = rdvs[dayIndex].some((rdv) => {
      return (
        (start < rdv.ending && start > rdv.beginning) ||
        (end < rdv.ending && end > rdv.beginning)
      );
    });

    if (isOverlapping) {
      console.error("Cannot create RDV: overlapping time slot");
      return;
    }

    try {
      const newRdv = {
        clientName: clientName,
        agentName: currentAgent,
        date: date.toISOString().split("T")[0],
        beginning: start,
        ending: end,
        adresse: adresse,
      };
      const response = await fetch("/new-rdv", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newRdv),
      });

      if (response.ok) {
        fetchRdvs();
      } else {
        console.error("Failed to create RDV:", await response.text());
      }
    } catch (error) {
      console.error("Error creating RDV:", error);
    }
  };

  const deleteRdv = async (index, dayIndex) => {
    if (rdvs.length === 0) return;
    const rdvToDelete = rdvs[dayIndex].find((rdv) => rdv.beginning === index);
    if (rdvToDelete) {
      const rdvId = rdvToDelete.id;
      try {
        const response = await fetch(`/delete-rdv`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: rdvId }),
        });
        if (response.ok) {
          fetchRdvs();
        } else {
          console.error("Failed to delete RDV:", await response.text());
        }
      } catch (error) {
        console.error("Error deleting RDV:", error);
      }
    }
  };

  const generateTimeSlots = () => {
    function startOfWeek(date) {
      var diff =
        date.getDate() +
        currentWeek * 7 -
        date.getDay() +
        (date.getDay() === 0 ? -6 : 1);

      return new Date(date.setDate(diff));
    }

    const slots = [];
    const startOfDay = startOfWeek(new Date());
    startOfDay.setHours(8, 0, 0, 0);

    for (let day = 0; day < 5; day++) {
      const daySlots = [];
      const currentDay = new Date(startOfDay);
      currentDay.setDate(currentDay.getDate() + day);
      for (let i = 0; i < 36; i++) {
        const time = new Date(currentDay);
        time.setMinutes(time.getMinutes() + i * 15);

        const isRdvStart = rdvs[day].some((rdv) => {
          return i === rdv.beginning;
        });
        daySlots.push({
          time: time,
          interval: i,
          isRdvStart: isRdvStart,
        });
      }
      slots.push(daySlots);
    }
    return slots;
  };

  useEffect(() => {
    setTimeSlots(generateTimeSlots());
  }, [rdvs]);

  const handleSubmit = () => {
    createRdv(date, dayIndex, startInterval, endInterval);
    setDate(null);
    setDayIndex(null);
    setStartInterval(null);
    setEndInterval(null);
    setDetailsPopup(false);
  };

  const handleCancel = () => {
    setDate(null);
    setDayIndex(null);
    setStartInterval(null);
    setEndInterval(null);
    setDetailsPopup(false);
  };

  const changeWeek = (forward = true) => {
    if (!forward) {
      setCurrentWeek(currentWeek - 1);
    } else {
      setCurrentWeek(currentWeek + 1);
    }
  };
  return (
    <div className="agent-calendar">
      <div className="change-time">
        <button className="reculer" onClick={() => changeWeek(false)}>
          <p>Reculer</p>
        </button>
        <button className="avancer" onClick={() => changeWeek(true)}>
          <p>avancer</p>
        </button>
      </div>
      <div className="agent-schedule">
        {detailsPopup && (
          <DetailsPopup
            setAdresse={setAdresse}
            setClientName={setClientName}
            handleSubmit={handleSubmit}
            handleCancel={handleCancel}
          />
        )}
        {timeSlots.map((daySlots, dayIndex) => (
          <div key={dayIndex} className="day">
            <h3>
              {["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"][dayIndex]}{" "}
              {daySlots[0].time.getDate()}{" "}
              {daySlots[0].time.toLocaleString("fr-FR", {
                month: "long",
              })}
            </h3>
            <div className="time-slots">
              {daySlots.map((time, index) => {
                const timeString = time.time.toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                });

                const isOccupied = rdvs[dayIndex].some((rdv) => {
                  return index >= rdv.beginning && index <= rdv.ending;
                });

                return (
                  <div
                    key={index}
                    className={`time-slot ${isOccupied ? "occupied" : ""} ${
                      startIndex <= index &&
                      endIndex >= index &&
                      startIndex !== null &&
                      endIndex !== null &&
                      draggingDayIndex === dayIndex
                        ? "hovered"
                        : ""
                    }`}
                    onMouseDown={() =>
                      handleMouseDown(time.time, index, dayIndex)
                    }
                    onMouseUp={() => handleMouseUp(time.time, dayIndex)}
                    onMouseEnter={() => handleMouseEnter(time.time, index)}
                  >
                    {timeString}
                    {isOccupied && <span className="rdv-indicator">RDV</span>}
                    {time.isRdvStart && (
                      <button
                        className="delete-rdv-button"
                        onClick={() => deleteRdv(index, dayIndex)}
                      >
                        Effacer
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AgentCalendar;
