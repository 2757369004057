import { React, Suspense, useEffect, useState } from "react";
import "./User.css";
import Appointment from "./Appointment/Appointment";
import Success from "./Appointment/Succes/Success";
import Cancel from "./Appointment/Cancel/Cancel";

const User = () => {
  const [token, setToken] = useState("");
  const [cancel, setCancel] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setToken(urlParams.get("token"));
  }, []);
  return (
    <div className="user-container">
      <Suspense fallback={<div className="loading-text">Chargement...</div>}>
        {token && !cancel && !success && (
          <Appointment
            token={token}
            setCancel={setCancel}
            setSuccess={setSuccess}
          />
        )}
        {!token && (
          <div className="loading-text">
            Cette page n'est accessible que par le biais d'un lien personnalisé.
          </div>
        )}
        {cancel && <Cancel />}
        {success && <Success />}
      </Suspense>
    </div>
  );
};

export default User;
