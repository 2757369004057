import { React, useState, useEffect } from "react";
import "./CreateAgent.css";
import CreationForm from "./CreationForm/CreationForm";

const CreateAgent = ({ setCurrentSettings }) => {
  const [agents, setAgents] = useState([]);

  const fetchAgents = async () => {
    const response = await fetch("/get-agents");
    const data = await response.json();
    setAgents(data);
  };

  const deleteAgent = (id) => {
    fetch("/delete-agent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    fetchAgents();
  };

  useEffect(() => {
    fetchAgents();
  }, []);

  useEffect(() => {
    console.log(agents);
  }, [agents]);
  return (
    <div className="create-agent-container creation-container">
      <h2>Tout les agents</h2>
      <button onClick={() => setCurrentSettings("Landing")}>Retour</button>
      <ul>
        {agents.map((agent) => (
          <div key={agent.id} className="agent-card">
            <p>{agent.name}</p>
            <p>{agent.region}</p>
            <p>{agent.adresse}</p>
            <button onClick={() => deleteAgent(agent.id)}>Supprimer</button>
          </div>
        ))}
      </ul>
      <CreationForm fetchAgents={fetchAgents} />
    </div>
  );
};

export default CreateAgent;
