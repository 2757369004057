import { React, useState, useEffect } from "react";
import "./Appointment.css";
import Header from "./Header/Header";
import RdvCard from "./RdvCard/RdvCard";
import gsap from "gsap";

const Appointment = ({ token, setCancel, setSuccess }) => {
  const [possibleRdv, setPossibleRdv] = useState([]);
  const [operationName, setOperationName] = useState(null);

  const [selectedRdv, setSelectedRdv] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      console.log("fetching");
      fetch(`/get-rdv-by-token/${token}`)
        .then((response) => response.json())
        .then((data) => {
          setPossibleRdv(data.rdv);
          setOperationName(data.operations[0].name);
        })
        .catch((error) => {
          setError(true);
          console.error("Error:", error);
        });
    }
  }, [token]);

  const sendRdv = (rdv) => {
    if (rdv) {
      setLoading(true);
      fetch("/make-appointment", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ rdv, token }),
      })
        .then((response) => response.json())
        .then((data) => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    }
  };

  const handleRdvClick = (rdv) => {
    setSelectedRdv(rdv);
  };

  const handleValidate = () => {
    if (selectedRdv) {
      sendRdv(selectedRdv);
    }
  };

  const handleCancel = () => {
    setSelectedRdv(null);
    const transition = gsap.timeline();
    transition.to(".appointment-container", {
      opacity: 0,
      duration: 0.3,
      ease: "power1.in",
      onComplete: () => {
        setCancel(true);
      },
    });
  };
  return (
    <div className="appointment-container">
      {error && <div className="error-message">Une erreur est survenue</div>}
      {!error && (
        <>
          <div className="header-wrapper">
            {operationName ? (
              <Header operationName={operationName} />
            ) : (
              <h1>Nous cherchons des créneaux pour votre RDV</h1>
            )}
          </div>
          <div className="rdv-wrapper">
            {possibleRdv.map((rdv, index) => (
              <RdvCard
                rdv={rdv}
                key={index}
                index={index}
                handleRdvClick={handleRdvClick}
                selectedRdv={selectedRdv}
              />
            ))}
          </div>
          <div className={`button-wrapper ${selectedRdv ? "active" : ""}`}>
            <button
              onClick={() => handleValidate()}
              className="validate-button"
            >
              Valider
            </button>
            <button onClick={() => handleCancel()} className="cancel-button">
              Aucun créneau ne me convient
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Appointment;
