import React from "react";
import { Route, Navigate } from "react-router-dom";
import { useAuth } from "./AuthProvider";
import Admin from "../Admin/Admin";

const AdminRoute = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Admin /> : <Navigate to="/login" />;
};

export default AdminRoute;
