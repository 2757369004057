import "./DetailsPopup.css";

export default function DetailsPopup({
  setAdresse,
  setClientName,
  handleSubmit,
  handleCancel,
}) {
  return (
    <div className="details-popup">
      <div className="details-popup-content">
        <h2>Détails Du Rendez-vous</h2>
        <input
          type="text"
          placeholder="Adresse"
          onChange={(e) => setAdresse(e.target.value)}
        />
        <input
          type="text"
          placeholder="Nom du client"
          onChange={(e) => setClientName(e.target.value)}
        />
        <button onClick={handleSubmit}>Valider</button>
        <button onClick={handleCancel}>Annuler</button>
      </div>
    </div>
  );
}
