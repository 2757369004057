import "./NavigationTab.css";
import { useEffect, useState, useRef } from "react";

const NavigationTab = ({ setCurrentPage, agentsNames }) => {
  useEffect(() => {
    console.log("agentsNames", agentsNames);
  }, [agentsNames]);
	
	
  return (
    <div className="navigation-tab">
      <button onClick={() => setCurrentPage("calendar")}>Calendar</button>
      <button onClick={() => setCurrentPage("settings")}>Settings</button>
      <button onClick={() => setCurrentPage("new_data")}>New Data</button>
    </div>
  );
};

export default NavigationTab;
