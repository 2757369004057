import React from "react";
import "./Settings.css";
import CreateAgent from "./CreateAgent/CreateAgent";
import CreateOperation from "./CreateOperation/CreateOperation";
import { useState, useEffect } from "react";

const SettingsLanding = ({ setCurrentSettings }) => {
  return (
    <div className="settings-landing-container">
      <h1>Settings</h1>
      <button onClick={() => setCurrentSettings("Agents")}>
        Nouveau Agent
      </button>
      <button onClick={() => setCurrentSettings("Operations")}>
        Nouvelle Opération
      </button>
    </div>
  );
};
const Settings = ({ setCurrentPage }) => {
  const [currentSettings, setCurrentSettings] = useState("Landing");

  return (
    <div className="settings-container">
      <button onClick={() => setCurrentPage(null)} className="back-button">
        Back
      </button>
      {currentSettings === "Landing" ? (
        <SettingsLanding setCurrentSettings={setCurrentSettings} />
      ) : null}
      {currentSettings === "Agents" ? (
        <CreateAgent setCurrentSettings={setCurrentSettings} />
      ) : null}
      {currentSettings === "Operations" ? (
        <CreateOperation setCurrentSettings={setCurrentSettings} />
      ) : null}
    </div>
  );
};

export default Settings;
