import { React, useEffect, useState } from "react";
import AgentSelector from "./AgentSelector/AgentSelector";
import AgentCalendar from "./AgentCalendar/AgentCalendar";
import "./Calendar.css";

const Calendar = ({ setCurrentPage }) => {
  const [currentAgent, setCurrentAgent] = useState(null);

  return (
    <div className="calendar-container">
      <button onClick={() => setCurrentPage(null)} className="back-button">
        Back
      </button>
      {currentAgent === null ? (
        <AgentSelector setCurrentAgent={setCurrentAgent} />
      ) : (
        <AgentCalendar
          currentAgent={currentAgent}
          setCurrentAgent={setCurrentAgent}
        />
      )}
    </div>
  );
};

export default Calendar;
