import "./RdvCard.css";
import gsap from "gsap";
import { useLayoutEffect, useRef } from "react";

const RdvCard = ({ rdv, index, handleRdvClick, selectedRdv, key }) => {
  const cardRef = useRef(null);

  useLayoutEffect(() => {
    gsap.from(cardRef.current, {
      opacity: 0,
      delay: 0.3,
      y: 50,
      duration: 0.5,
      ease: "power3.inOut",
    });
  }, []);

  const rdvClasses = ["first", "second", "third"];
  const isSelected = selectedRdv === rdv;
  const getBackToHours = (timeSlot, baseHour = 8) => {
    const minutes = (timeSlot * 15) % 60;
    const hourOffset = Math.floor((timeSlot * 15) / 60);
    const hour = baseHour + hourOffset;

    return `${hour}h${minutes === 0 ? "00" : minutes}`;
  };
  const formatToStandard = (dateString) => {
    const date = new Date(dateString);

    const days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    const months = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];

    const dayName = days[date.getDay()];
    const dayNumber = date.getDate();
    const monthName = months[date.getMonth()];

    return `${dayName} ${dayNumber} ${monthName}`;
  };
  return (
    <div
      className={`rdv-card ${
        isSelected ? `selected ${rdvClasses[index]}` : ""
      }`}
      ref={cardRef}
      key={key}
      onClick={() => handleRdvClick(rdv)}
    >
      <h3>{"Le " + formatToStandard(rdv.date)}</h3>
      <p>{"Commence à " + getBackToHours(rdv.beginning)}</p>
      <p>{"Durée de l'opération : " + getBackToHours(rdv.time, 0)}</p>
    </div>
  );
};

export default RdvCard;
