import { React, useState, useEffect } from "react";
import "./AgentSelector.css";
import Error from "../../Commun/Error/Error";
const AgentSelector = ({ setCurrentAgent }) => {
  const [agents, setAgents] = useState([]);
  const [error, setError] = useState(false);
  useEffect(() => {
    fetchAgents();
  }, []);

  const fetchAgents = async () => {
    try {
      const response = await fetch("/get-agents");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setAgents(data);
    } catch (error) {
      console.error("Failed to fetch agents:", error);
      setError(true);
    }
  };

  return (
    <div className="agent-selector-container">
      <h1>Sélectionner un agent de terrain</h1>
      {agents.length > 0 &&
        agents.map((agent) => (
          <button
            key={agent.id}
            onClick={() => setCurrentAgent(agent.name)}
            className="agent-button"
          >
            {agent.name}
          </button>
        ))}
      {error && <Error error={error} />}
    </div>
  );
};

export default AgentSelector;
