import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../Security/AuthProvider"; // Ensure this path is correct
import "./Login.css";

const Login = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth(); // Get login function from context

  const handleLogin = async () => {
    setLoading(true); // Set loading state to true
    try {
      const response = await fetch("/login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ password }), // Send password to the server
      });

      if (!response.ok) {
        throw new Error("Incorrect password!"); // Handle error response
      }

      const data = await response.json();
      console.log(data.message); // Log success message
      login(); // Call login function
      navigate("/admin"); // Redirect to Admin page
    } catch (error) {
      alert(error.message); // Show error message
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="login-container">
      <h1>Connexion</h1>
      <input
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter password"
      />
      <button onClick={handleLogin} disabled={loading}>
        {loading ? "Chargement..." : "Se connecter"}
      </button>
      {loading && <p>Chargement, veuillez patienter...</p>}
    </div>
  );
};

export default Login;
