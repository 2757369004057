import gsap from "gsap";
import { useEffect, useRef } from "react";

const Cancel = () => {
  const textRef = useRef(null);
  useEffect(() => {
    gsap.from(textRef.current, {
      opacity: 0,
      duration: 0.5,
      ease: "power1.in",
    });
  }, []);
  return (
    <div ref={textRef} className="loading-text">
      Vous recevrez un mail dès que nous trouverons un créneau pour vous
    </div>
  );
};

export default Cancel;
