import gsap from "gsap";
import { useEffect, useRef } from "react";

const Success = () => {
  const textRef = useRef(null);
  useEffect(() => {
    gsap.from(textRef.current, {
      opacity: 0,
      duration: 0.5,
      ease: "power1.in",
    });
  }, []);
  return (
    <div ref={textRef} className="loading-text">
      Votre RDV a été confirmé avec succès, vous recevrez un mail de
      confirmation avec les détails de votre RDV.
    </div>
  );
};

export default Success;
