import "./Header.css";

const Header = ({ operationName }) => {
  return (
    <div className="header-container">
      <h1>{`Nous vous avons trouvé des créneaux pour votre ${operationName}`}</h1>
      <h2>Merci de choisir celui qui vont convient le plus</h2>
    </div>
  );
};

export default Header;
