import React from "react";
import "./NewData.css";

const NewData = ({ setCurrentPage }) => {
  return (
    <div className="new-data-container">
      <button onClick={() => setCurrentPage(null)}>Back</button>
      <h1>New Data</h1>
      <form action="/upload-csv" method="post" enctype="multipart/form-data">
        <input type="file" name="file" accept=".csv" required />
        <button type="submit">Upload</button>
      </form>
    </div>
  );
};

export default NewData;
