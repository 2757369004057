import { React, useState, useEffect } from "react";
import "./CreateOperation.css";

const CreateOperation = ({ setCurrentSettings }) => {
  const [operations, setOperations] = useState([]);

  const fetchOperations = async () => {
    const response = await fetch("/get-operations");
    const data = await response.json();
    setOperations(data);
  };

  const deleteOperation = (id) => {
    fetch("/delete-operation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id }),
    });
    fetchOperations();
  };

  useEffect(() => {
    fetchOperations();
  }, []);

  const [operationData, setOperationData] = useState({
    code: "",
    duration: "",
    name: "",
  });

  const handleChange = (e) => {
    setOperationData({ ...operationData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/new-operation", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(operationData),
      });
      fetchOperations();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="creation-container create-operation-container">
      <h2>Toute les Opérations</h2>
      <button onClick={() => setCurrentSettings("Landing")}>Retour</button>
      <ul>
        {operations.map((operation) => (
          <div key={operation.id} className="operation-card">
            <p>NOM : {operation.name}</p>
            <p>CODE : {operation.code}</p>
            <p>Durée : {operation.duration * 15} mins</p>
            <button onClick={() => deleteOperation(operation.id)}>
              Supprimer
            </button>
          </div>
        ))}
      </ul>
      <div className="create-agent-form-container">
        <h2 className="create-agent-title">Créer un agent</h2>
        <form className="create-agent-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nom de l'Opérations</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="code">Code</label>
            <input
              type="text"
              id="code"
              name="code"
              required
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="duration">Durée (en bloc de 15 mins)</label>
            <input
              type="text"
              id="duration"
              name="duration"
              required
              onChange={handleChange}
            />
          </div>
          <button type="submit">Créer</button>
        </form>
      </div>
    </div>
  );
};

export default CreateOperation;
