import React, { useEffect, useState } from "react";
import Calendar from "./Calendar/Calendar";
import "./Admin.css";
import Settings from "./Settings/Settings";
import NewData from "./Calendar/NewData/NewData";
import NavigationTab from "./NavigationTab/NavigationTab";

const Admin = () => {
  const [agents, setAgents] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);

  useEffect(() => {
    fetch("/get-agents")
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        setAgents(data);
      });
  }, []);

  useEffect(() => {
    console.log("currentPage", currentPage);
  }, [currentPage]);

  return (
    <div className="admin-container">
      <NavigationTab
        setCurrentPage={setCurrentPage}
        agentsNames={agents.map((agent) => agent.name)}
      />
      {currentPage === "calendar" && (
        <Calendar setCurrentPage={setCurrentPage} />
      )}
      {currentPage === "settings" && (
        <Settings setCurrentPage={setCurrentPage} />
      )}
      {currentPage === "new_data" && (
        <NewData setCurrentPage={setCurrentPage} />
      )}
    </div>
  );
};

export default Admin;
