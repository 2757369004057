import { React, useState, useEffect } from "react";
import "./CreationForm.css";

const CreationForm = ({ fetchAgents }) => {
  const [agentData, setAgentData] = useState({
    name: "",
    region: "",
    adresse: "",
  });

  const handleChange = (e) => {
    setAgentData({ ...agentData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/new-agent", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(agentData),
      });
      fetchAgents();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="create-agent-form-container">
      <h2 className="create-agent-title">Créer un agent</h2>
      <form className="create-agent-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nom de l'agent</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="region">Région</label>
          <input
            type="text"
            id="region"
            name="region"
            required
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="adresse">Adresse</label>
          <input
            type="text"
            id="adresse"
            name="adresse"
            required
            onChange={handleChange}
          />
        </div>
        <button type="submit">Créer</button>
      </form>
    </div>
  );
};

export default CreationForm;
